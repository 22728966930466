import React, { ReactElement, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getLoggedInUser, User } from "../domain/authService";

export function RequireAuth({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    getLoggedInUser()
      .then(setUser)
      .then(() => {
        return setHasLoaded(true);
      })
      .catch((error) => {
        console.log("User not logged with error:", error);
        setHasLoaded(true);
        return;
      });
  }, []);

  if (!hasLoaded) {
    return <></>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function WithoutAuth({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    getLoggedInUser()
      .then(setUser)
      .then(() => {
        return setHasLoaded(true);
      })
      .catch((error) => {
        console.log("Was not able to get the user", error);
        setHasLoaded(true);
        return;
      });
  }, []);

  if (!hasLoaded) {
    return <></>;
  }

  if (user) {
    return <Navigate to="/not-found" state={{ from: location }} replace />;
  }

  return children;
}
