import React, { ReactElement } from "react";

export function Error({
  children,
}: {
  children: string | ReactElement;
}): ReactElement {
  return (
    <div
      data-testid="error-message"
      className="border border-red-500 p-2 my-2 rounded bg-red-200 text-red-900"
    >
      {children}
    </div>
  );
}
