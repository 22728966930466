import { baseUrl } from "../../core/data/client";
import { putInvoice } from "../data/invoiceClient";

export function update(month: number, year: number): Promise<void> {
  return putInvoice(month, year);
}

export function download(month: number, year: number): void {
  window.open(`${baseUrl}invoice?month=${month}&year=${year}`, "_blank");
}
