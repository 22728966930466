import axios from "axios";
import {
  baseUrl,
  configWithCredentials,
  errorLogging,
} from "../../core/data/client";

export function putInvoice(month: number, year: number): Promise<void> {
  return axios
    .put(
      `${baseUrl}invoice`,
      { month: month, year: year },
      configWithCredentials
    )
    .then(({ data }) => data)
    .catch(errorLogging);
}

export function getInvoice(month: number, year: number): Promise<any> {
  return axios
    .get(`${baseUrl}invoice?month=${month}&year=${year}`, configWithCredentials)
    .then(({ data }) => data)
    .catch(errorLogging);
}
