import React, { ReactElement, useState } from "react";
import { Input } from "../../core/presentation/Input";
import { FilledButton } from "../../core/presentation/Buttons";
import { Error } from "../../core/presentation/Error";
import { login } from "../domain/authService";
import { useNavigate } from "react-router-dom";

function renderError(errorMessage: null | string): ReactElement {
  if (!errorMessage) {
    return <></>;
  }
  return <Error>{errorMessage}</Error>;
}

function handleSubmit(
  email: null | string,
  password: null | string,
  successFn: () => void,
  errorFn: (error: string) => void
): (event: any) => void {
  return (event) => {
    event.preventDefault();
    if (!email || !password) {
      errorFn("Bitte füllen Sie das E-Mail und Passwort Feld aus.");
    } else {
      login(email, password)
        .then(() => successFn())
        .catch((error) => {
          console.error(error);
          errorFn(
            "Leider konnten Sie nicht angemeldet werden, versuchen Sie es bitte noch einmal."
          );
        });
    }
  };
}

export function Login(_props: any): ReactElement {
  const [email, setEmail] = useState<null | string>(null);
  const [password, setPassword] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();

  return (
    <section className="w-1/2 mx-auto my-8 bg-surface p-6 rounded-lg text-on-surface drop-shadow">
      <h1 className="text-2xl font-semi-bold">Anmelden</h1>
      Bitte melden Sie sich an.
      {renderError(error)}
      <Input
        id="email"
        label="E-Mail"
        required={true}
        type="email"
        placeholder="E-Mail Adresse"
        data-testid="e-mail"
        onChange={setEmail}
        value={email || ""}
      />
      <Input
        id="password"
        label="Passwort"
        required={true}
        type="password"
        placeholder="Passwort"
        data-testid="password"
        onChange={setPassword}
        value={password || ""}
      />
      <p className="pt-2">
        <FilledButton
          onClick={handleSubmit(
            email,
            password,
            () => {
              navigate("/", { replace: true });
              location.reload();
            },
            setError
          )}
          data-testid="login"
        >
          anmelden
        </FilledButton>
      </p>
    </section>
  );
}
