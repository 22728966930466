import React, { ReactElement } from "react";
import { Title } from "../../core/presentation/Typography";

export function ProductGroups(_props: any): ReactElement {
  return (
    <>
      <Title>Produkt Gruppen</Title>
    </>
  );
}
