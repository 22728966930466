import React, { ReactElement, useState } from "react";
import { Input } from "../../core/presentation/Input";
import { SubTitle, Title } from "../../core/presentation/Typography";
import { FilledButton } from "../../core/presentation/Buttons";
import { download, update } from "../domain/invoiceService";
import { Icon } from "../../core/presentation/Icon";

function handleUpdate(month: number, year: number, setLoading: any): void {
  setLoading(true);
  update(month, year)
    .then(() => {
      console.log("Updated invoices");
      return setLoading(false);
    })
    .catch((error) => {
      console.error("Error updating invoices: ", error);
      setLoading(false);
    });
}

function handleDownload(month: number, year: number): void {
  download(month, year);
}

function renderLoading(loading: boolean | null): ReactElement {
  if (loading == null) {
    return <>aktualisieren</>;
  }
  if (loading) {
    return (
      <>
        <Icon id="ri-scan-line"></Icon> aktualisiert
      </>
    );
  }
  return (
    <>
      <Icon id="ri-check-line"></Icon> hat aktualisiert
    </>
  );
}

export function Invoices(_props: any): ReactElement {
  const today = new Date();
  const [month, setMonth] = useState<number>(today.getMonth());
  const [year, setYear] = useState<number>(today.getFullYear());
  const [loading, setLoading] = useState<boolean | null>(null);

  return (
    <>
      <Title>Rechnungen</Title>
      <section className="mt-4 w-72">
        <SubTitle>1. Zeitraum wählen</SubTitle>
        <Input
          id="month"
          label="Monat"
          required={true}
          type="number"
          value={month}
          onChange={setMonth}
          data-testid="month"
        />
        <Input
          id="year"
          label="Jahr"
          required={true}
          type="number"
          value={year}
          onChange={setYear}
          data-testid="year"
        />
      </section>
      <section className="mt-4">
        <SubTitle>2. Rechnungen aktualisieren</SubTitle>
        <p className="mt-4">
          Rechnungen aktualisieren für den Zeitraum 01.
          {String(month).padStart(2, "0")}.{year} -{" "}
          {new Date(year, month, 0).getDate()}.{String(month).padStart(2, "0")}.
          {year}
        </p>
        <p className="mt-4">
          <FilledButton onClick={() => handleUpdate(month, year, setLoading)}>
            {renderLoading(loading)}
          </FilledButton>
        </p>
      </section>
      <section className="mt-4">
        <SubTitle>3. Rechnungen drucken</SubTitle>
        <p className="mt-4">
          Rechnungen herunterladen und drucken für den Zeitraum 01.
          {String(month).padStart(2, "0")}.{year} -{" "}
          {new Date(year, month, 0).getDate()}.{String(month).padStart(2, "0")}.
          {year}
        </p>
        <p className="mt-4">
          <FilledButton onClick={() => handleDownload(month, year)}>
            herunterladen
          </FilledButton>
        </p>
      </section>
    </>
  );
}
