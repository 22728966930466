import React, { ReactElement } from "react";

export function Title({
  children,
}: {
  children: ReactElement | string;
}): ReactElement {
  return <h1 className="text-2xl font-semi-bold">{children}</h1>;
}

export function SubTitle({
  children,
}: {
  children: ReactElement | string;
}): ReactElement {
  return <h2 className="text-xl font-semi-bold">{children}</h2>;
}
