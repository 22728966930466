import React, { ReactElement } from "react";
import { Title } from "../../core/presentation/Typography";
import { FilledButton } from "../../core/presentation/Buttons";
import { createBakinglist } from "../domain/bakingListService";

export function Orders(_props: any): ReactElement {
  return (
    <>
      <Title>Bestellungen</Title>
      <p>
        <FilledButton onClick={createBakinglist}>
          Backliste Unterland drucken
        </FilledButton>
      </p>
    </>
  );
}
