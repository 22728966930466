import React, { ReactElement, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { getLoggedInUser, logout, User } from "../../auth/domain/authService";
import { Icon } from "./Icon";
import style from "./App.module.css";

function handleLogout(): void {
  logout();
  location.reload();
}

function Navigation({ user }: { user: User | null }): ReactElement {
  if (!user) {
    return (
      <>
        <Link to="/login" className="ml-8 font-medium">
          Anmelden
        </Link>
      </>
    );
  }
  return (
    <>
      <div className={style.navigationLinks}>
        <Link to="/orders" className="ml-8 font-medium">
          Bestellungen
        </Link>
        <Link to="/invoices" className="ml-20 font-medium hover:text-secondary">
          Rechnungen
        </Link>
      </div>
      <div className="w-72 flex flex-row justify-between">
        <div>Hallo {user.name || user.email}</div>
        <a className="ml-8 font-medium cursor-pointer" onClick={handleLogout}>
          Abmelden <Icon id="ri-logout-box-line" />
        </a>
      </div>
    </>
  );
}

export function App(_props: any): ReactElement {
  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    getLoggedInUser()
      .then(setUser)
      .catch((error) =>
        console.error(`Was not able to get user with error: ${error}`)
      );
  }, []);

  return (
    <>
      <header
        data-testid="header"
        className="h-14 leading-10 py-2 border-b sticky top-0 w-full"
      >
        <div className="max-w-screen-xl mx-auto flex flex-row">
          <Link to="/" className="font-bold text-primary w-48">
            WAWI - Der Inselbäcker
          </Link>
          <nav
            data-testid="navigation"
            className="text-primary flex flex-row justify-around"
          >
            <Navigation user={user} />
          </nav>
        </div>
      </header>

      <main data-testid="main" className="max-w-screen-xl mx-auto">
        <Outlet />
      </main>
    </>
  );
}
