import { baseUrl } from "../../core/data/client";

export enum Location {
  Unterland,
  Oberland,
}

export function createBakinglist(): void {
  const date = new Date();
  window.open(
    `${baseUrl}bakinglist?date=${date.toISOString()}&location=${location}`
  );
}
