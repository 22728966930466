import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";

export function NotFound(_props: any): ReactElement {
  const location = useLocation();

  return (
    <section className="">
      <h1>😓 404 - Seite nicht gefunden</h1>
      Die Seite ({location.pathname}) konnte leider nicht gefunden werden.
    </section>
  );
}
