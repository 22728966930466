import React, { ReactElement } from "react";
import "remixicon/fonts/remixicon.css";

export function Icon({ id }: { id: string }): ReactElement {
  return (
    <i
      className={`${id} inline-block align-middle`}
      style={{ marginTop: "-1px" }}
    />
  );
}
