import React, { Dispatch, ReactElement, SetStateAction } from "react";

function renderRequiredHint(required: boolean): ReactElement {
  if (required) {
    return <sup className="text-red-500">*</sup>;
  }
  return <></>;
}

export function Input({
  id,
  label,
  required,
  type,
  placeholder,
  autofocus,
  onChange,
  value,
  ...rest
}: {
  id: string;
  label: string;
  required: boolean;
  type: string;
  placeholder?: string;
  autofocus?: boolean;
  onChange?: Dispatch<SetStateAction<any>>;
  value?: any;
}): ReactElement {
  return (
    <p className="mt-4">
      <label
        htmlFor={id}
        className="block w-full mb-2 text-on-surface-variant text-sm"
      >
        {label}
        {renderRequiredHint(required)}:
      </label>
      <input
        type={type}
        id={id}
        name={id}
        className="block w-full px-3 py-1.5 rounded border border-outline h-10 text-on-surface
          transition duration-200 ease-in-out focus:border-primary focus:outline-none"
        placeholder={placeholder}
        autoFocus={autofocus}
        onChange={(event) => (onChange ? onChange(event.target.value) : null)}
        value={value}
        {...rest}
      />
    </p>
  );
}
