import React, { ReactElement } from "react";

export function FilledButton({
  children,
  onClick,
  ...rest
}: {
  children?: ReactElement | string;
  onClick?: (event: any) => any;
}): ReactElement {
  return (
    <button
      className="bg-primary text-on-primary rounded-full leading-5 px-4 py-2 transition duration-300 ease-in-out hover:bg-secondary focus:outline focus:outline-offset-2 focus:outline-outline focus:outline-2"
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
