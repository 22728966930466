import axios from "axios";
import {
  baseUrl,
  configWithCredentials,
  errorLogging,
} from "../../core/data/client";

export interface IDToken {
  token: string;
}

export function authenticate(
  email: string,
  password: string
): Promise<IDToken> {
  return axios
    .post(
      `${baseUrl}auth/token`,
      { email: email, password: password },
      configWithCredentials
    )
    .then(({ data }) => data)
    .catch(errorLogging);
}
