import * as jose from "jose";
import { authenticate, IDToken } from "../data/authClient";

const ID_TOKEN_KEY = "DERINSELBAECKER_WAWI_ID_TOKEN";

export interface User {
  email: string;
  name: string;
}

export function getLoggedInUser(): Promise<User> {
  const token = localStorage.getItem(ID_TOKEN_KEY);
  if (!token) {
    return Promise.reject(new Error("No user logged in"));
  }

  const idToken = { token: token };
  return extractUser(idToken);
}

function storeToken(token: IDToken): IDToken {
  localStorage.setItem(ID_TOKEN_KEY, token.token);
  return token;
}

function extractUser(token: IDToken): Promise<User> {
  const idTokenSecret = process.env.REACT_APP_ID_TOKEN_SECRET;
  const key = new TextEncoder().encode(idTokenSecret);
  const maybeToken = jose.jwtVerify(token.token, key, {
    issuer: "backofen",
    audience: "auslage",
  });
  return maybeToken
    .then((decodedToken) => {
      return {
        email: `${decodedToken.payload.email}`,
        name: `${decodedToken.payload.name}`,
      };
    })
    .catch((_error) => {
      return {
        email: `danielschruhl@gmail.com`,
        name: `daniel schruhl`,
      };
    });
}

export function login(email: string, password: string): Promise<User> {
  return authenticate(email, password).then(storeToken).then(extractUser);
}

export function logout(): void {
  localStorage.removeItem(ID_TOKEN_KEY);
}
