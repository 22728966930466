import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import { RequireAuth, WithoutAuth } from "./auth/presentation/Authorization";
import { App } from "./core/presentation/App";
import { NotFound } from "./core/presentation/NotFound";
import { Login } from "./auth/presentation/Login";
import { Invoices } from "./invoice/presentation/Invoices";
import { ProductGroups } from "./products/presentation/ProductGroupList";
import { Orders } from "./orders/presentation/orders";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            }
          />
          <Route
            path="invoices"
            element={
              <RequireAuth>
                <Invoices />
              </RequireAuth>
            }
          />
          <Route
            path="login"
            element={
              <WithoutAuth>
                <Login />
              </WithoutAuth>
            }
          />

          <Route
            path="product-groups"
            element={
              <RequireAuth>
                <ProductGroups />
              </RequireAuth>
            }
          />

          <Route
            path="orders"
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
